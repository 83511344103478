.construction-modal-wrapper {
    border-radius: 12px;

    .construction-modal {
        padding: $spacing-24 $spacing-48;
        position: relative;
        background-color: $english-violet;
        color: white;

        .header {
            font-size: $h3;
            padding-right: $spacing-48;
        }

        .body-text {
            font-size: $normal;
        }

        .close-button {
            position: absolute;
            top: $spacing-8;
            right: $spacing-8;
        }
    }
}
