/*
font-family: 'IBM Plex Mono', monospace;
font-family: 'IBM Plex Sans', sans-serif;
font-family: 'IBM Plex Sans JP', sans-serif;
font-family: 'IBM Plex Sans KR', sans-serif;
*/

html:lang(en) > * {
    font-family: 'IBM Plex Sans', sans-serif;
}

html:lang(ja) {
    font-family: 'IBM Plex Sans JP', sans-serif;
}

html:lang(ko) {
    font-family: 'IBM Plex Sans KR', sans-serif;
}