@import "./themes.scss";

#project-container {
  flex: 1 1 100%;
  padding: $spacing-64 $spacing-16;
  display: flex;
  gap: $spacing-16;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  overflow: auto;

  .project-button {
    padding: 0;
    background-color: transparent;
    border: 0;
    outline: 0;
  }

  .flip-box {
    background-color: transparent;
    width: inherit;
    height: inherit;
    perspective: 1000px;
  }

  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .flip-box:hover .flip-box-inner {
    transform: rotateY(-180deg);
  }

  .flip-box-front,
  .flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: $spacing-12;
  }

  .flip-box-front {
    color: black;
  }

  .flip-box-back {
    background-color: #0b0b0c;
    color: white;
    /*
    @include themify($themes) {
      background-color: themed("backgroundColorTertiary");
      color: themed("textColorTertiary");
    }
    */
    transform: rotateY(-180deg);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .extrasVideoWrapper {
    position: relative;
    margin: $spacing-12;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .extrasVideo {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 0;
    min-height: 0;
    z-index: -100;
    background-size: cover;
    overflow: hidden;
  }

  .extrasDetailsContainer > * {
    padding: $spacing-12;
  }

  .extrasDetailsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px auto;
    flex: 1 1 100%;
    min-height: 0;

    .extrasDetailsName {
      padding-top: 0;
      padding-bottom: 0;
      font-weight: bold;
      text-wrap: balance;
      font-size: $h6;
      line-height: 1em;
      flex: 0 0 max-content;
    }
  
    .extrasDetailsRelease {
      padding-top: $spacing-8;
      padding-bottom: $spacing-8;
      font-size: $small;
      flex: 0 0 max-content;
    }
  
    .extrasDetailsDescription {
      display: flex;
      flex-direction: column;
      padding-top: $spacing-12;
      font-size: $small;
      line-height: 1em;
      flex: 1 1 100%;
      min-height: 0;
      overflow: auto;

      @media only screen and (max-width: $breakpoint-laptop) {
        font-size: 0.9em;
      }

      span {
        margin-bottom: $spacing-16;

        &:last-of-type {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}
