@import "./themes.scss";

$scrollbar-width: 5px;

/*
@media only screen and (max-width: $breakpoint-desktop) {
}

@media only screen and (max-width: $breakpoint-small-desktop) {
}

@media only screen and (max-width: $breakpoint-laptop) {
}

@media only screen and (max-width: $breakpoint-small-laptop) {
}
*/

body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 10;
  transition: opacity 3s linear;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 3s linear;
}

.fade-exit {
  opacity: 1;
  transition: opacity 3s linear;
}

.fade-exit.fade-exit-active {
  z-index: 15;
  opacity: 0;
  transition: opacity 3s linear;
}

/* width */
::-webkit-scrollbar {
  width: $scrollbar-width;
}

/* Track */
::-webkit-scrollbar-track {
  width: $scrollbar-width;
  background-color: rgba(1,1,1,1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: $scrollbar-width;
  background-color: rgba(43, 43, 43, 0.85);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(26, 26, 26, 0.85);
}

// Color variables
$rich-black: #0E131F;
$delft-blue: #38405f;
$english-violet: #59546c;
$cadet-gray: #8b939c;
$imperial-red: #ff0035;

// Spacing variables
$spacing-4: var(--spacing-4);
$spacing-8: var(--spacing-8);
$spacing-12: var(--spacing-12);
$spacing-16: var(--spacing-16);
$spacing-24: var(--spacing-24);
$spacing-32: var(--spacing-32);
$spacing-40: var(--spacing-40);
$spacing-48: var(--spacing-48);
$spacing-64: var(--spacing-64);

// Breakpoints
$breakpoint-desktop: 1920px;
$breakpoint-small-desktop: 1600px;
$breakpoint-laptop: 1280px;
$breakpoint-small-laptop: 1024px;

// Type scaling
$smallest: 0.694rem;
$small: 0.833rem;
$normal: 1rem;
$h6: 1.2rem;
$h5: 1.44rem;
$h4: 1.728rem;
$h3: 2.074rem;
$h2: 2.488rem;
$h1: 2.986rem;

// Typeface root sizes
// 1920 Breakpoint
:root {
  font-size: 16px;
  --spacing-4: 4px;
  --spacing-8: 8px;
  --spacing-12: 12px;
  --spacing-16: 16px;
  --spacing-24: 24px;
  --spacing-32: 32px;
  --spacing-40: 40px;
  --spacing-48: 48px;
  --spacing-64: 64px;
}

@media only screen and (max-width: $breakpoint-small-desktop) {
  :root {
    font-size: 14px;
    --spacing-4: 3px;
    --spacing-8: 6px;
    --spacing-12: 9px;
    --spacing-16: 12px;
    --spacing-24: 18px;
    --spacing-32: 24px;
    --spacing-40: 30px;
    --spacing-48: 36px;
    --spacing-64: 48px;
  }
}

@media only screen and (max-width: $breakpoint-laptop) {
  :root {
    font-size: 12px;
    --spacing-4: 2px;
    --spacing-8: 4px;
    --spacing-12: 6px;
    --spacing-16: 8px;
    --spacing-24: 12px;
    --spacing-32: 16px;
    --spacing-40: 20px;
    --spacing-48: 24px;
    --spacing-64: 32px;
  }
}

