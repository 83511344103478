.main-window {
    height: 100vh;
    width: 100vw;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $delft-blue;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;

    &.PhasmophobiaGhostHuntersIndex {
        background-image: linear-gradient(
          to bottom,
          rgba(5, 5, 5, 0),
          rgba(5, 5, 5, 0.2),
          rgba(5, 5, 5, 1)
        ),
        url("../../images/PhasmophobiaBackground.jpg");
    }

    &.ForewarnedMejaiBanishersIndex {
        background-image: linear-gradient(
          to bottom,
          rgba(5, 5, 5, 0),
          rgba(5, 5, 5, 0.2),
          rgba(5, 5, 5, 1)
        ),
        url("../../images/ForewarnedBackground.jpg");
    }

    #navigation {
        background-color: rgba($rich-black, 0.8);
        width: 100%;
        min-height: 0;
        flex: 0 0 80px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;
        transition: background-color 300ms ease-in-out;
        z-index: 9999;
        
        @media only screen and (max-width: $breakpoint-small-desktop) {
            flex-basis: 72px;
        }
        
        @media only screen and (max-width: $breakpoint-laptop) {
            flex-basis: 64px;
        }

        &:hover {
            // background-color: rgba($rich-black, 0.4);
        }

        &.project-url {
            &:hover {
                background-color: rgba($rich-black, 0.9);
            }
        }

        .nav-left {
            height: 100%;

            .logo-wrapper {
                height: 100%;
                padding: 14px 0 14px $spacing-32;
                cursor: default;

                &.primed-animation:hover {
                    cursor: pointer;
                }

                .logo {
                    height: 100%;

                    svg path {
                        fill: $imperial-red;
                    }
                }
            }
        }

        .nav-center {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            gap: 10px;

            .nav-option {
                position: relative;
                background: none;
                color: inherit;
                border: none;
                padding: 10px $spacing-32;
                font: inherit;
                cursor: pointer;
                outline: inherit;
                background-color: $english-violet;
                border-top-right-radius: 10px;

                .nav-label {
                    color: white;
                    font-size: $h6;
                    font-weight: 500;
                    position: relative;
                }

                .hover-underline {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0%;
                    height: 4px;
                    background-color: $imperial-red;
                    content: '';
                    transition: width 100ms linear;
                }

                &:hover {
                    cursor: pointer;

                    .hover-underline {
                        width: 100%;
                    }
                }

                #last-opened-project {
                    position: absolute;
                    top: 50%;
                    left: calc(100% - $spacing-24);
                    transform: translateY(-50%);
                    display: flex;
                    align-items: center;
                    gap: $spacing-8;

                    svg path {
                        fill: white;
                    }

                    #last-opened-project-name {
                        white-space: nowrap;
                        color: white;
                        font-size: $normal;
                        font-weight: 500;
                        position: relative;
                    }
                }
            }
        }

        .nav-right {
            padding-right: $spacing-32;

            #social-links {
                display: flex;
                gap: $spacing-16;

                svg {
                    height: 32px;
                    width: 32px;
                    transition: transform 75ms linear;
                    
                    @media only screen and (max-width: $breakpoint-small-desktop) {
                        height: 28px;
                        width: 28px;
                    }
                    
                    @media only screen and (max-width: $breakpoint-laptop) {
                        height: 24px;
                        width: 24px;
                    }

                    path {
                        fill: white;
                    }

                    &:hover {
                        transform: scale(120%);
                    }
                }
            }
        }
    }
}