@import "./themes.scss";

// This SASS file is imported by 'index.js' in order to append custom CSS styles to the body tag.
// In order to use in javascript, use "window.getComputedStyle(document.body)" and then "<bodyStyles>.getPropertyValue(name)".
// The name is formatted like this:  --<theme>-<variableName>

@function custom-property-name($name) {
  @return $prefix + $name;
}

@mixin define-custom-property($name, $value) {
  #{custom-property-name($name)}: $value;
}

body {
  // Append SASS variables which are desired to be accesible:
  @each $theme, $map in $themes {
    @each $key, $submap in $map {
      @include define-custom-property(
        #{$theme + "-" + $key},
        map-get(map-get($themes, $theme), "#{$key}")
      );
    }
  }
}
